:root {
  --primary-color: whitesmoke;
  --primary-color-rgb: 240, 240, 240;
  --accent-color: rgb(45, 48, 146);
  --accent-color-rgb: 45, 48, 146;

  --text-color: rgb(45, 45, 45);
  --text-color-rgb: 45, 45, 45;
  --heading-color: rgb(0, 0, 0);

  --font-sans: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-serif: Georgia, "Times New Roman", Times, serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  margin: 0;
}

#root,
#__next {
  isolation: isolate;
}

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  background: var(--primary-color);
  /* background: linear-gradient(to bottom right, var(--primary-color), var(--accent-color) 250%); */
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-family: var(--font-sans);
}

* {
  font-family: var(--font-sans);
}

/* html, body, #root, #root > div {
  height: 100%;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .btn-secondary {
  color: #6c757d;
  border-color: #6c757d;
  background: transparent;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #ced4da;
}

.btn-light {
  color: #212529;
  background-color: #e9ecef;
  border-color: #ced4da;
} */

/* ELEMENTS */
.text-box {
  display: flex;
  margin: .5rem 0;
  padding: .25rem 0 .75rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.text-box .top {
  display: flex;
  flex-basis: 33%;
  justify-content: space-between;
  padding-right: 12px;
}

.text-box .bottom {
  display: flex;
  flex-basis: 66%;
}

.top div {
  flex-basis: 50%;
}

.timestamp {
  font-size: .85rem;
}

.speaker {
  text-align: left;
  font-size: .85rem;
}

.transcript {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: -.1px;
}

@media screen and (max-width: 968px) {
  .transcript {
    margin-top: .35rem;
  }

  .text-box {
    flex-direction: column;
  }

  .speaker {
    text-align: right
  }
}

/* Speakers list */
.list-group h6:not(:last-child) {
  margin-bottom: .75rem;
}

.list-group h6 {
  font-weight: 400;
  letter-spacing: -.04rem;
  font-size: 1.1rem;
  line-height: 1.1;
}

.list-group-item {
  padding: 1rem;
  transition: background-color 200ms ease, border-color 200ms ease;
  -webkit-transition: background-color 200ms ease, border-color 200ms ease;
  -moz-transition: background-color 200ms ease, border-color 200ms ease;
  -ms-transition: background-color 200ms ease, border-color 200ms ease;
  -o-transition: background-color 200ms ease, border-color 200ms ease;
}

.list-group-item.active {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.button.active {
  background-color: var(--accent-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

/* SIDE BUTTONS */
.side-buttons {
  position: fixed;
  height: 10vh;
  right: 1rem;
  left: unset;
  display: flex;
  flex-direction: row;
  top: 0;
}

.side-buttons button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.81);
}

/* NAVBAR */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: var(--accent-color);
  /* padding: 2rem 1rem; */
  height: 10vh;
  color: var(--primary-color);
}

.navbar-title {
  font-size: 1.75rem;
  font-weight: 900;
  letter-spacing: -0.03rem;
}

.timer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding-bottom: 6px;
}

/* BUTTONS */
.buttons {
  text-align: right;
}

.button {
  text-transform: uppercase;
  font-size: .7rem;
  padding: 3px 7px;
  margin-right: 3px;
  border: 1px solid #ced4da;
  background: var(--primary-color);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  font-weight: 400;
  color: rgba(0, 0, 0, .7)
}

.button:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #ced4da;
}


/* BODY */
.body-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 1rem 0;
  max-height: 90vh;
  min-height: 90vh;
  overflow-y: hidden;
}

.sidebar {
  flex: 33%;
  min-width: 540px;
}

.transcripts {
  flex: 66%;
}

@media screen and (max-width: 768px) {
  .body-wrapper {
    flex-direction: column;
    max-height: none;
  }

  .sidebar,
  .transcripts {
    flex: 1;
  }

  .sidebar {
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .35);
    padding-bottom: 2rem;
  }

  .navbar-title {
    font-size: 1rem;
  }

  .side-buttons {
    position: unset;
    top: unset;
    right: unset;
    left: unset;
    display: flex;
    flex-direction: row;
    text-align: center;
    place-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    height: auto;
  }
  .side-buttons button {
    color: var(--accent-color)
  }
}